import { inject } from '@angular/core';
import {
	collection,
	CollectionReference,
	doc,
	DocumentReference,
	Firestore,
} from '@angular/fire/firestore';
import { ActivityService } from '@context/frontend/activity';
import { ORGANIZATION_ID, USER } from '@context/frontend/common';
import { PaginatedObject } from '@context/frontend/pagination';
import { Activity } from '@context/shared/types/activity';
import { Entity } from '@context/shared/types/common';
import { User } from '@context/shared/types/user';

type LogActivityPayloadBase = {
	entity: string | DocumentReference<Entity>;
	activity: Pick<Activity, 'type' | 'data'>;
	createdBy: string | DocumentReference<User>;
	collectionRef?: never | CollectionReference<Entity>;
};

interface LogActivityEntityRefPayload extends LogActivityPayloadBase {
	entity: DocumentReference<Entity>;
	collectionRef?: never;
}

interface LogActivityEntityIdPayload extends LogActivityPayloadBase {
	entity: string;
	collectionRef: CollectionReference<Entity>;
}

export type LogActivityPayload =
	| LogActivityEntityIdPayload
	| LogActivityEntityRefPayload;

export abstract class BaseActivityService<T extends Entity> {
	protected readonly activityService = inject(ActivityService);
	protected readonly firestore = inject(Firestore);

	protected readonly organizationId = inject(ORGANIZATION_ID);
	protected readonly user = inject(USER);

	/**
	 * Logs a new activity item onto the collection that this service is responsible for.
	 *
	 * @note If a collection has not be determined or initialized then the activity cannot
	 * be logged from an entity<string>.id. You will need to provide the DocumentReference.
	 * Sometimes, it may make more sense to inject the activity service directly into the
	 * implementation and provide a collection manually.
	 *
	 * @param entity either the entity ID or the entity document reference to apply the log to
	 * @param type the type of activity to log
	 * @returns the newly logged activity item
	 */
	logActivity(payload: LogActivityPayload) {
		const { activity, entity, collectionRef, createdBy } = payload;
		if (typeof entity === 'string' && !collectionRef)
			throw new Error(
				'Collection has not be initialized on this service',
			);

		const { type, data } = activity;

		// determines the createdBy of the entity based on the id provided
		// and the current organization's user collection
		let createdByRef: null | DocumentReference<User> = null;
		if (typeof createdBy === 'string') {
			const userCollection = collection(
				this.firestore,
				`organizations/${this.organizationId.value}/users`,
			) as CollectionReference<User>;
			createdByRef = doc(userCollection, createdBy);
		} else createdByRef = createdBy;

		const entityRef =
			typeof entity === 'string'
				? doc(collectionRef as CollectionReference<T>, entity)
				: entity;

		return this.activityService.create(
			entityRef,
			{ type, data },
			createdByRef,
		);
	}

	/**
	 * Fetches and returns the activity logs tied to the entity that is provided.
	 *
	 * @note If a collection has not been determined or initialized, then the activity cannot be
	 * fetched from an entity<string>.id. You will need to provide the DocumentReference.
	 * Sometimes, it may make more sense to inject the activity service directly into the
	 * implementation and provide a collection manually.
	 *
	 * @param entity either the entity ID or the entity document reference to fetch the logs from.
	 * @returns an array of activity logs tied to this entity
	 */
	fetchActivity(
		payload: Pick<LogActivityPayload, 'entity' | 'collectionRef'>,
	) {
		const { entity, collectionRef } = payload;
		if (typeof entity === 'string' && !collectionRef)
			throw new Error(
				'Collection has not be initialized on this service',
			);

		const entityRef =
			typeof entity === 'string'
				? doc(collectionRef as CollectionReference<T>, entity)
				: entity;

		return this.activityService.fetch(
			new PaginatedObject({ parentRef: entityRef }),
		);
	}
}
